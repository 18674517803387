<template>
<div class="text-center">
    <PageTitle :title="title"></PageTitle>
    <video width="90%" height="100%" controls autoplay>
        <source :src="src" type="video/mp4">
    </video>
    <p class="font-italic">{{caption}}</p>
</div>
</template>

<script>
const PageTitle = () => import("@/components/common/PageTitle");

export default {
    name: "Page",
    props: {
        "src": String,
        "title": String,
        "caption": String
    },
    components: {
        PageTitle
    },
};
</script>
